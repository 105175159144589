<template>
	<v-container fluid>
		<filtro
			ref="filtro"
			:propLastUpdate="dtLastUpdate"
			:propLoad="loadMes || loadLista"
			@filtrar="filtrar(true)"
		></filtro>
		<!-- Indicadores -->
		<v-row class="mt-3">
			<v-col md="3">
				<Indicadores
					titulo="Taxa de Ocupação"
					:valor="indicadores.comprometido_p"
					icone="$percentageCircle"
					:load="loadMes"
				/>
			</v-col>
			<v-col md="3">
				<Indicadores
					titulo="Valor Total Espaço"
					:valor="indicadores.espaco_v"
					icone="$expandIcon"
					:load="loadMes"
				/>
			</v-col>
			<v-col md="3">
				<Indicadores
					titulo="Comprometido c/ Desconto"
					:valor="indicadores.investimento"
					icone="$tagDiscount"
					:load="loadMes"
				/>
			</v-col>
			<v-col md="3">
				<Indicadores
					titulo="Oportunidade"
					:valor="indicadores.oportunidade_v"
					icone="$moneyCircle"
					:load="loadMes"
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col
				cols="12"
				sm="12"
				:md="layoutCompacto ? 6 : 12"
				:lg="layoutCompacto ? 6 : 12"
				:xl="layoutCompacto ? 6 : 12"
				class=""
			>
				<v-card class="my-0 br-15 pa-5 pt-3" elevation="0">
					<v-icon
						style="position: absolute; top: 15px; left: 20px; z-index: 2"
						left
						@click="mudaLayout()"
						title="Layout compacto"
						color="#bbb"
						>{{
							layoutCompacto
								? "mdi-arrow-expand"
								: "mdi-arrow-collapse"
						}}</v-icon
					>

					<div class="grafico">
						<div class="grafico-frame"></div>
						<highcharts
							:options="graficoMes"
							ref="grafico1"
						></highcharts>
					</div>

					<v-row class="mt-2">
						<v-col cols="6" class="pb-0">
							<div class="legenda-oportunidades">
								<div class="comprometido"></div>
								Comprometido
							</div>
						</v-col>
						<v-col cols="6" class="pb-0">
							<div class="legenda-oportunidades">
								<div class="oportunidade"></div>
								Oportunidades
							</div>
						</v-col>
					</v-row>
					<v-overlay :absolute="true" :value="loadMes">
						<v-progress-circular indeterminate size="40" />
					</v-overlay>
				</v-card>
			</v-col>
			<v-col
				cols="12"
				sm="12"
				:md="layoutCompacto ? 6 : 12"
				:lg="layoutCompacto ? 6 : 12"
				:xl="layoutCompacto ? 6 : 12"
			>
				<v-card class="my-0 br-15 pa-5 pt-3" elevation="0">
					<v-icon
						style="position: absolute; top: 15px; left: 20px; z-index: 2"
						left
						@click="mudaLayout()"
						title="Layout compacto"
						color="#bbb"
						>{{
							layoutCompacto
								? "mdi-arrow-expand"
								: "mdi-arrow-collapse"
						}}</v-icon
					>
					<div class="grafico">
						<div class="grafico-frame"></div>
						<highcharts
							:options="graficoAno"
							ref="grafico2"
						></highcharts>
					</div>
					<v-row class="mt-2">
						<v-col cols="6" class="pb-0">
							<div class="legenda-oportunidades">
								<div class="comprometido"></div>
								Comprometido
							</div>
						</v-col>
						<v-col cols="6" class="pb-0">
							<div class="legenda-oportunidades">
								<div class="oportunidade"></div>
								Oportunidades
							</div>
						</v-col>
					</v-row>
					<v-overlay :absolute="true" :value="loadMes">
						<v-progress-circular indeterminate size="40" />
					</v-overlay>
				</v-card>
			</v-col>
		</v-row>
		<v-card class="px-5 py-3 br-15 w-100 my-4" elevation="0">
			<v-row class="mb-2">
				<v-col cols="2" class="d-flex pt-2">
					<InfoBox expands>
						<p>
							<span
								style="color: var(--v-primary-base); font-weight: 500"
							>
								Oportunidades:
							</span>
							Com ênfase em mostrar os dados relacionados as
							oportunidades de maneira detalhada, a planilha pode
							ser visualizada através dos meses selecionados ou
							dos valores totais desse período. Para escolher qual
							modo deseja visualizar, selecione a opção desejada
							no canto superior esquerdo da tela.
						</p>
						<p>
							Na aba "oportunidades (%)" as cores nos círculos são
							diretamente correspondentes a porcentasem dos
							valores. sendo o verde utilizado para representar os
							valores de
							<span
								style="color: var(--v-primary-base); font-weight: 500"
							>
								0% a 50%</span
							>, o amarelo para valores de
							<span style="color: #ff9f1c; font-weight: 500">
								51% a 80%</span
							>
							e o vermelho para valores de
							<span
								style="color: var(--v-red-base); font-weight: 500"
							>
								81% a 100%</span
							>.
						</p>
					</InfoBox>
				</v-col>
				<v-col cols="8" class="pt-2">
					<h2 class="text-center" style="font-weight: normal">
						Oportunidades
					</h2>
				</v-col>
				<v-col cols="2" class="d-flex justify-end pt-2">
					<v-menu offset-y min-width="180">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								dark
								color="#f2f2f2"
								v-bind="attrs"
								v-on="on"
								small
								class="px-1"
								style="min-width: unset"
							>
								<v-icon color="#CCC">mdi-menu</v-icon>
							</v-btn>
						</template>
						<v-list dense nav>
							<v-list-item link @click="exportar('oportunidade')">
								<v-list-item-icon class="pl-2 mr-0">
									<v-icon color="grey lighten-1"
										>mdi-microsoft-excel</v-icon
									>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title
										>Exportar CSV</v-list-item-title
									>
								</v-list-item-content>
							</v-list-item>
						</v-list>
						<v-list dense nav>
							<v-list-item link @click="exportar('propostaComercial')">
								<v-list-item-icon class="pl-2 mr-0">
									<v-icon color="grey lighten-1"
										>mdi-microsoft-excel</v-icon
									>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title
										>Proposta Comercial em CSV</v-list-item-title
									>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-menu>
				</v-col>
			</v-row>
			<v-data-table
				:headers="headers"
				:items="dataTable"
				class="elevation-0 tabela"
				:options.sync="pagination"
				:server-items-length="total"
				:items-per-page="5"
				:footer-props="tableConfig"
				:header-props="{ sortIcon: 'mdi-chevron-down' }"
				multi-sort
			>
				<template v-slot:header="{}">
					<thead>
						<tr>
							<th
								v-for="(h, i) in headersGroup"
								:key="i"
								:colspan="h.colspan"
								:class="h.class"
							>
								{{ h.text }}

								<v-icon
									v-if="h.estacao === 'verao'"
									color="#c28f5b"
									class="mx-1"
									size="15"
								>
									mdi-white-balance-sunny
								</v-icon>
								<v-icon
									v-if="h.estacao === 'inverno'"
									color="#1e7199"
									class="mx-1"
									size="15"
								>
									mdi-snowflake
								</v-icon>
								<div class="" small color="white" v-if="i == 0">
									<v-radio-group
										hide-details
										v-model="expand"
										class="pa-0 ma-0 expand-buttons"
										dark
										row
									>
										Visualizar:
										<v-radio
											size
											label="Total do período selecionado"
											:value="false"
										></v-radio>
										<v-radio
											label="Meses do período selecionado"
											:value="true"
										></v-radio>
									</v-radio-group>
								</div>
							</th>
						</tr>
					</thead>
				</template>
				<template slot="body.append">
					<tr class="total">
						<td
							v-for="(h, i) in headersTotal"
							:key="i"
							:colspan="h.colspan"
							:class="h.class"
						>
							{{ h.text }}
						</td>
					</tr>
				</template>
				<template
					v-for="p in getPeriodo()"
					v-slot:[`item.p_oportunidade_${p.valor}`]="{ item }"
				>
					<td :key="p.valor" class="center-cell">
						<div
							:class="
								getClass(item['p_oportunidade_' + p.valor], 'o')
							"
						></div>
						{{ item["p_oportunidade_" + p.valor] }}
					</td>
				</template>

				<template v-slot:[`item.p_oportunidade`]="{ item }">
					<td class="center-cell">
						<div :class="getClass(item.p_oportunidade, 'o')"></div>
						{{ item.p_oportunidade }}
					</td>
				</template>
			</v-data-table>
			<v-overlay :absolute="true" :value="loadLista">
				<v-progress-circular indeterminate size="40" />
			</v-overlay>
		</v-card>
		<v-card class="px-5 py-3 br-15 w-100 my-6" elevation="0">
			<v-row class="mb-2">
				<v-col cols="2" class="d-flex pt-2">
					<InfoBox expands>
						Visualização da quantidade dos espaços por cluster com o
						percentual da taxa de ocupação.</InfoBox
					>
				</v-col>
				<v-col cols="8" class="pt-2">
					<h2 class="text-center" style="font-weight: normal">
						Ocupação
					</h2>
				</v-col>
				<v-col cols="2" class="d-flex justify-end pt-2">
					<v-menu offset-y min-width="180">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								dark
								color="#f2f2f2"
								v-bind="attrs"
								v-on="on"
								small
								class="px-1"
								style="min-width: unset"
							>
								<v-icon color="#CCC">mdi-menu</v-icon>
							</v-btn>
						</template>
						<v-list dense nav>
							<v-list-item link @click="exportar('ocupacao')">
								<v-list-item-icon class="pl-2 mr-0">
									<v-icon color="grey lighten-1"
										>mdi-microsoft-excel</v-icon
									>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title
										>Exportar CSV</v-list-item-title
									>
								</v-list-item-content>
							</v-list-item>
						</v-list>
						
					</v-menu>
				</v-col>
			</v-row>
			<v-data-table
				class="elevation-0 tabela"
				:headers="headersOcupacao"
				:items="dataTable"
				:items-per-page="5"
				:options.sync="pagination"
				:server-items-length="total"
				:footer-props="tableConfig"
				:header-props="{ sortIcon: 'mdi-chevron-down' }"
				multi-sort
			>
				<template
					v-for="h in headersOcupacao"
					v-slot:[`header.${h.value}`]="{ header }"
				>
					<div
						:key="h.text"
						v-html="header.text"
						style="display: inline-block; text-align: center"
					></div>
				</template>
				<template slot="body.append">
					<tr class="total">
						<td
							v-for="(h, i) in headersOcupacaoTotal"
							:key="i"
							:colspan="h.colspan"
							:class="h.class"
						>
							{{ h.text }}
						</td>
					</tr>
				</template>
			</v-data-table>
			<v-overlay :absolute="true" :value="loadLista">
				<v-progress-circular indeterminate size="40" />
			</v-overlay>
		</v-card>
		<v-card class="px-5 py-3 br-15 w-100 my-6" elevation="0">
			<v-row class="mb-2">
				<v-col cols="2" class="d-flex pt-2">
					<InfoBox expands>
						Visualização dos valores dos espaços comprometidos por
						cluster e meses selecionados.</InfoBox
					>
				</v-col>
				<v-col cols="8" class="pt-2">
					<h2 class="text-center" style="font-weight: normal">
						Ocupação por Mês
					</h2>
				</v-col>
				<v-col cols="2" class="d-flex justify-end pt-2">
					<v-menu offset-y min-width="180">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								dark
								color="#f2f2f2"
								v-bind="attrs"
								v-on="on"
								small
								class="px-1"
								style="min-width: unset"
							>
								<v-icon color="#CCC">mdi-menu</v-icon>
							</v-btn>
						</template>
						<v-list dense nav>
							<v-list-item link @click="exportar('investimento')">
								<v-list-item-icon class="pl-2 mr-0">
									<v-icon color="grey lighten-1"
										>mdi-microsoft-excel</v-icon
									>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title
										>Exportar CSV</v-list-item-title
									>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-menu>
				</v-col>
			</v-row>
			<v-data-table
				class="elevation-0 tabela"
				:headers="headersInvestimento"
				:items="dataTable"
				:items-per-page="5"
				:options.sync="pagination"
				:server-items-length="total"
				:footer-props="tableConfig"
				:header-props="{ sortIcon: 'mdi-chevron-down' }"
				multi-sort
			>
				<template slot="body.append">
					<tr class="total">
						<td
							v-for="(h, i) in headersInvestimentoTotal"
							:key="i"
							:colspan="h.colspan"
							:class="h.class"
						>
							{{ h.text }}
						</td>
					</tr>
				</template>
			</v-data-table>
			<v-overlay :absolute="true" :value="loadLista">
				<v-progress-circular indeterminate size="40" />
			</v-overlay>
		</v-card>
	</v-container>
</template>

<script>
import Filtro from "./Filtro.vue";
import Indicadores from "./_components/Indicadores.vue";
import InfoBox from "../../../components/InfoBox.vue";

var graficoMesGlobal = {};
var graficoAnoGlobal = {};


export default {

	components: {
		Filtro,
		Indicadores,
		InfoBox,
	},
	data() {
		return {
			dados: [],
			campos: [],
			// mesInicial: this.$moment().format("YYYY-MM"),
			// mesFinal: this.$moment().format("YYYY-MM"),
			larguraOportunidadeMes: 800,
			pagination: {},
			total: null,
			carregando: false,
			expand: false,
			expandIcon: "mdi-plus",
			dadosMes: [],
			layoutCompacto: true,
			dadosMesGraf: {},
			dadosAnoGraf: {},
			tableConfig: {
				itemsPerPageOptions: [5, 10, 15, 25, 50, 100],
				itemsPerPageText: "Linhas por páginas",
				ofText: "de",
				pageText: "{0}-{1} de {2}",
			},
			graficoMes: {
				chart: {
					height: 400,
					type: "column",
					marginTop: 60,
					marginBottom: 60,
					backgroundColor: "transparent",
					scrollablePlotArea: {
						scrollPositionX: 0,
					},
				},
				credits: {
					enabled: false,
				},
				tooltip: {
					enabled: false,
				},
				title: {
					text: "Oportunidades - Visualização Mensal",
				},

				yAxis: {
					max: 100,
					gridLineWidth: 0,
					title: {
						enabled: false,
					},
					labels: {
						enabled: false,
					},
					stackLabels: {
						enabled: true,
						allowOverlap: false,
						useHTML: true,
						formatter: function() {
							return `<div class="stackLabels"><div class="${
								this.stack
							}">${this.total}%</div> ${graficoMesGlobal[
								this.stack
							][this.x].slice(0, -3)}</div>`;
						},
					},
				},
				plotOptions: {
					column: {
						stacking: "normal",
						pointPadding: 1,
						states: {
							pointPadding: 0.2,
							inactive: {
								opacity: 1,
							},
						},
						maxPointWidth: 60,
					},
					series: {
						borderRadiusTopLeft: "8px",
						borderRadiusTopRight: "8px",
						groupPadding: 0.1,
						pointPadding: 0.25,
						pointWidth: 80,
					},
				},
				xAxis: {
					categories: [],
					lineColor: "transparent",
					labels: {
						autoRotation: 0,
						style: {
							whiteSpace: "nowrap",
						},
					},
				},
				exporting: {
					scale: 1,
					chartOptions: {
						chart: {
							backgroundColor: "#fff",
							marginBottom: 80,
						},
						legend: {
							enabled: true,
						},
					},
					xAxis: {
						lineColor: "#ccc",
					},
					yAxis: {
						stackLabels: {
							formatter: function() {
								return graficoMesGlobal[this.stack][this.x];
							},
						},
					},
				},

				legend: {
					enabled: false,
				},
				series: [
					{
						name: "Comprometido",
						stack: "comprometido",
						color: this.$vuetify.theme.themes.light.yellow,
						dataLabels: {
							style: {
								textOutline: this.$vuetify.theme.themes.light
									.yellow,
							},
						},
						data: [],
					},
					{
						name: "Oportunidade",
						stack: "oportunidade",
						color: this.$vuetify.theme.themes.light.primary.base,
						dataLabels: {
							style: {
								textOutline: this.$vuetify.theme.themes.light
									.primary.base,
							},
						},
						data: [],
					},
				],
			},
			graficoAno: {
				chart: {
					height: 400,
					type: "column",
					marginTop: 60,
					marginBottom: 60,
					backgroundColor: "transparent",
					scrollablePlotArea: {
						scrollPositionX: 0,
					},
				},
				credits: {
					enabled: false,
				},
				tooltip: {
					enabled: false,
				},
				title: {
					text: "Oportunidades - Visualização Anual",
				},

				yAxis: {
					max: 100,

					gridLineWidth: 0,
					title: {
						enabled: false,
					},
					labels: {
						enabled: false,
					},
					stackLabels: {
						enabled: true,
						allowOverlap: false,
						useHTML: true,
						formatter: function() {
							return `<div class="stackLabels"><div class="${
								this.stack
							}">${this.total}%</div> ${graficoMesGlobal[
								this.stack
							][this.x].slice(0, -3)}</div>`;
						},
					},
				},
				plotOptions: {
					column: {
						stacking: "normal",
						pointPadding: 1,
						states: {
							pointPadding: 0.2,
							inactive: {
								opacity: 1,
							},
						},
						maxPointWidth: 60,
					},
					series: {
						borderRadiusTopLeft: "8px",
						borderRadiusTopRight: "8px",
						groupPadding: 0.1,
						pointPadding: 0.25,
						pointWidth: 80,
					},
				},
				xAxis: {
					categories: [],
					lineColor: "transparent",
					labels: {
						autoRotation: 0,
						style: {
							whiteSpace: "nowrap",
						},
					},
				},
				exporting: {
					scale: 1,
					chartOptions: {
						chart: {
							backgroundColor: "#fff",
							marginBottom: 80,
						},
						legend: {
							enabled: true,
						},
					},
					yAxis: {
						stackLabels: {
							formatter: function() {
								return graficoAnoGlobal[this.stack][this.x];
							},
						},
					},
				},

				legend: {
					enabled: false,
				},
				series: [
					{
						name: "Comprometido",
						stack: "comprometido",
						color: this.$vuetify.theme.themes.light.yellow,
						dataLabels: {
							style: {
								textOutline: this.$vuetify.theme.themes.light
									.yellow,
							},
						},
						data: [],
					},
					{
						name: "Oportunidade",
						stack: "oportunidade",
						color: this.$vuetify.theme.themes.light.primary.base,
						dataLabels: {
							style: {
								textOutline: this.$vuetify.theme.themes.light
									.primary.base,
							},
						},
						data: [],
					},
				],
			},
			fnFiltrando: false,
			indicadores: {
				comprometido_p: 0,
				espaco_v: 0,
				investimento: 0,
				oportunidade_v: 0,
			},
			primeiraBusca: true,
			headersCampos: [
				{
					text: "Bandeira",
					value: "bandeira",
					align: "center",
					class: "th-separador min-width-100",
					grupo: "",
				},
				{
					text: "Espaço",
					value: "espaco",
					align: "center",
					class: "th-separador min-width-100",
					grupo: "",
				},
				{
					text: "Espaço Macro",
					value: "espaco_macro",
					align: "center",
					class: "th-separador min-width-100",

					grupo: "",
				},
				{
					text: "ID JAVA",
					value: "id_java",
					align: "center",
					class: "th-separador min-width-100",
					grupo: "",
				},
				{
					text: "CNPJ",
					value: "cnpj",
					align: "center",
					class: "th-separador min-width-100",
					grupo: "",
				},
				{
					text: "Cluster",
					value: "cluster",
					align: "center",
					class: "th-separador min-width-150",
					grupo: "",
				},
				{
					text: "Cluster Tabela",
					value: "cluster_macro",
					align: "center",
					class: "th-separador min-width-150",
					grupo: "",
				},
				{
					text: "Região",
					value: "regiao",
					align: "center",
					class: "th-separador min-width-100",
					grupo: "",
				},
				{
					text: "Cidade",
					value: "cidade",
					align: "center",
					class: "th-separador min-width-100",
					grupo: "",
				},
				{
					text: "Perfil",
					value: "perfil",
					align: "center",
					class: "th-separador min-width-100",
					grupo: "",
				},
				{
					text: "Tamanho",
					value: "tamanho",
					align: "center",
					class: "th-separador min-width-100",
					grupo: "",
				},
				{
					text: "Modelo CKO",
					value: "modelo_cko",
					align: "center",
					class: "th-separador min-width-100",
					grupo: "",
				},
				{
					text: "Valor Unit.",
					value: "vl_unitario",
					align: "center",
					class: "th-separador min-width-100",
					grupo: "",
				},
			],
			loadMes: false,
			loadLista: false,
			dtLastUpdate: null,
			agrupadosPage: false,
		};
	},
	watch: {
		pagination: {
			handler() {
				if (this.primeiraBusca) {
					this.$refs.filtro.iniciaFiltro();

					if (this.agrupadosPage) {
						setTimeout(() => {
							this.filtrar();
						}, 1000);
					}

					this.primeiraBusca = false;
				} else {
					if (!this.fnFiltrando && this.agrupadosPage) {
						this.filtrar(false, true);
					}
				}
			},
			deep: true,
		},
	},
	// provide() {
	// 	return {
	// 		listaCampos: this.listaCampos(),
	// 	};
	// },
	mounted() {},
	computed: {
		dataTable() {
			if (!this.agrupadosPage) {
				let _from =
					this.pagination.page > 0
						? this.pagination.itemsPerPage *
						  (this.pagination.page - 1)
						: 0;

				let _end = _from + this.pagination.itemsPerPage;

				return this.dados.slice(_from, _end);
			} else {
				return this.dados;
			}
		},
		headersGroup() {
			var headersGroup = [
				{
					colspan: this.getHeadersLength() + 3,
					class: "header-dark th-separador dark ",
				},
			];

			if (this.expand) {
				for (const p of this.getPeriodo()) {
					const mes = p.valor.split("_")[1];
					const estacao = this.getEstacao(mes);

					headersGroup.push({
						text: p.nome,
						colspan: 5,
						estacao: estacao,
						class: ` th-separador text-center ${estacao}-dark`,
						sortable: !this.agrupadosPage,
					});
				}
			}

			headersGroup.push({
				text: "Total",
				colspan: 5,
				class: "header-dark text-center th-separador",
				sortable: !this.agrupadosPage,
			});

			return headersGroup;
		},

		headersTotal() {
			var headersTotal = [];

			if (this.dados.length && this.dadosMes.length) {
				if (this.getHeadersLength() - 1 > 0) {
					headersTotal.push({
						text: null,
						colspan: this.getHeadersLength() - 1,
						class: null,
					});
				}

				headersTotal.push({
					text: "Total:",
					colspan: 1,
				});

				headersTotal.push({
					text: this.dadosMes[this.dadosMes.length - 1].filiais,
					colspan: 1,
				});

				headersTotal.push({
					text: this.dadosMes[this.dadosMes.length - 1].espaco,
					colspan: 1,
				});

				headersTotal.push({
					text: (
						this.dadosMes[this.dadosMes.length - 1].espaco_v * 1
					).toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					}),
					colspan: 1,
				});

				var espaco = 0;
				var comprometido = 0;
				var oportunidade = 0;
				var oportunidade_v = 0;

				for (const p of this.getPeriodo()) {
					let dadoMes = this.dadosMes.find((e) => e.mes == p.mes);

					espaco += dadoMes.espaco * 1;
					comprometido += dadoMes.comprometido * 1;
					oportunidade += dadoMes.oportunidade * 1;
					oportunidade_v += dadoMes.oportunidade_v * 1;

					if (this.expand) {
						headersTotal.push({
							text: dadoMes.comprometido,
							colspan: 1,
						});
						headersTotal.push({
							text:
								Math.round(
									(100 * dadoMes.comprometido) /
										dadoMes.espaco
								) + "%",
							colspan: 1,
						});
						headersTotal.push({
							text: dadoMes.oportunidade,
							colspan: 1,
						});
						headersTotal.push({
							text:
								Math.round(
									(100 * dadoMes.oportunidade) /
										dadoMes.espaco
								) + "%",
							colspan: 1,
						});
						headersTotal.push({
							text: (dadoMes.oportunidade_v * 1).toLocaleString(
								"pt-br",
								{
									style: "currency",
									currency: "BRL",
								}
							),
							colspan: 1,
						});
					}
				}

				headersTotal.push({
					text: comprometido,
					colspan: 1,
				});
				headersTotal.push({
					text: Math.round((100 * comprometido) / espaco) + "%",
					colspan: 1,
				});
				headersTotal.push({
					text: oportunidade,
					colspan: 1,
				});
				headersTotal.push({
					text: Math.round((100 * oportunidade) / espaco) + "%",
					colspan: 1,
				});
				headersTotal.push({
					text: oportunidade_v.toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					}),
					colspan: 1,
				});
			}

			return headersTotal;
		},
		headers() {
			var headers = [];

			for (const h of this.headersCampos) {
				if (this.campos.includes(h.value)) {
					h.sortable = !this.agrupadosPage;
					headers.push(h);
				}
			}

			headers.push({
				text: "Filiais",
				value: "filiais",
				align: "center",
				class: "th-separador min-width-100",
				sortable: !this.agrupadosPage,
				grupo: "",
			});
			headers.push({
				text: "Total",
				value: "quantidade",
				align: "center",
				class: "th-separador min-width-120",
				sortable: !this.agrupadosPage,
				grupo: "Espaço",
			});
			headers.push({
				text: "Valor Total",
				value: "v_quantidade",
				class: "th-separador min-width-150",
				sortable: !this.agrupadosPage,
				align: "center",
				grupo: "Espaço",
			});

			if (this.expand) {
				for (const p of this.getPeriodo()) {
					headers.push({
						text: "Comprometido",
						value: "comprometido_" + p.valor,
						class: "th-separador min-width-100",
						sortable: !this.agrupadosPage,
						align: "center",
						grupo: p.nome,
					});
					headers.push({
						text: "Comprometido (%)",
						value: "p_comprometido_" + p.valor,
						class: "th-separador min-width-100 ",
						sortable: !this.agrupadosPage,
						align: "center",
						grupo: p.nome,
					});
					headers.push({
						text: "Oportunidade",
						value: "oportunidade_" + p.valor,
						class: "th-separador min-width-100",
						sortable: !this.agrupadosPage,
						align: "center",
						grupo: p.nome,
					});
					headers.push({
						text: "Oportunidade (%)",
						value: "p_oportunidade_" + p.valor,
						class: "th-separador min-width-100",
						sortable: !this.agrupadosPage,
						align: "center",
						grupo: p.nome,
					});
					headers.push({
						text: "Oportunidade (R$)",
						value: "v_oportunidade_" + p.valor,
						class: "th-separador min-width-100",
						sortable: !this.agrupadosPage,
						align: "center",
						grupo: p.nome,
					});
				}
			}

			headers.push({
				text: "Comprometido",
				value: "comprometido",
				class: "th-separador min-width-100",
				align: "center",
				sortable: !this.agrupadosPage,
				grupo: "Total",
			});
			headers.push({
				text: "Comprometido (%)",
				value: "p_comprometido",
				class: "th-separador min-width-100",
				align: "center",
				sortable: !this.agrupadosPage,
				grupo: "Total",
			});
			headers.push({
				text: "Oportunidade",
				value: "oportunidade",
				class: "th-separador min-width-100",
				align: "center",
				sortable: !this.agrupadosPage,
				grupo: "Total",
			});
			headers.push({
				text: "Oportunidade (%)",
				value: "p_oportunidade",
				class: "th-separador min-width-100",
				align: "center",
				sortable: !this.agrupadosPage,
				grupo: "Total",
			});
			headers.push({
				text: "Oportunidade (R$)",
				value: "v_oportunidade",
				class: "th-separador min-width-100",
				align: "center",
				sortable: !this.agrupadosPage,
				grupo: "Total",
			});

			return headers;
		},
		headersInvestimento() {
			var headers = [];

			for (const h of this.headersCampos) {
				if (this.campos.includes(h.value)) {
					h.sortable = !this.agrupadosPage;
					headers.push(h);
				}
			}

			for (const p of this.getPeriodo()) {
				headers.push({
					text: p.nome,
					value: "investimento_" + p.valor,
					align: "center",
					class: "th-separador min-width-150",
					sortable: !this.agrupadosPage,
					grupo: p.nome,
				});
			}

			return headers;
		},
		headersInvestimentoTotal() {
			var headersTotal = [];

			if (this.dados.length && this.dadosMes.length) {
				if (this.getHeadersLength() - 1 > 0) {
					headersTotal.push({
						text: null,
						colspan: this.getHeadersLength() - 1,
					});
				}

				headersTotal.push({
					text: "Total",
					colspan: 1,
				});

				let dadoMes = {};

				for (const p of this.getPeriodo()) {
					dadoMes = this.dadosMes.find((e) => e.mes == p.mes);

					headersTotal.push({
						text: (dadoMes.investimento * 1).toLocaleString(
							"pt-br",
							{
								style: "currency",
								currency: "BRL",
							}
						),
						colspan: 1,
					});
				}
			}

			return headersTotal;
		},
		headersOcupacao() {
			var headers = [];

			for (const h of this.headersCampos) {
				if (this.campos.includes(h.value)) {
					h.sortable = !this.agrupadosPage;
					headers.push(h);
				}
			}

			headers.push({
				text: "Filiais",
				value: "filiais",
				align: "center",
				class: "th-separador min-width-120",
				sortable: !this.agrupadosPage,
				grupo: "",
			});
			headers.push({
				text: "Valor Total",
				value: "v_quantidade",
				class: "th-separador min-width-150",
				sortable: !this.agrupadosPage,
				align: "center",
				grupo: "",
			});

			let mesFinal = null;

			for (const p of this.getPeriodo()) {
				mesFinal = p;

				headers.push({
					text: "Taxa de Ocupação<br/>" + p.nome,
					value: "p_comprometido_" + p.valor,
					align: "center",
					class: "th-separador min-width-200 ",
					sortable: !this.agrupadosPage,
					grupo: p.nome,
				});
			}

			if (mesFinal) {
				headers.push({
					text: "Valor Disponivel",
					value: "v_oportunidade_" + mesFinal.valor,
					align: "center",
					class: "min-width-150",
					sortable: !this.agrupadosPage,
					grupo: "",
				});
			}

			return headers;
		},
		headersOcupacaoTotal() {
			var headersTotal = [];

			if (this.dados.length && this.dadosMes.length) {
				if (this.getHeadersLength() - 1 > 0) {
					headersTotal.push({
						text: null,
						colspan: this.getHeadersLength() - 1,
					});
				}

				headersTotal.push({
					text: "Total",
					colspan: 1,
				});

				headersTotal.push({
					text: this.dadosMes[this.dadosMes.length - 1].filiais,
					colspan: 1,
				});
				headersTotal.push({
					text: (
						this.dadosMes[this.dadosMes.length - 1].espaco_v * 1
					).toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					}),
					colspan: 1,
				});

				let dadoMes = {};

				for (const p of this.getPeriodo()) {
					dadoMes = this.dadosMes.find((e) => e.mes == p.mes);

					headersTotal.push({
						text:
							Math.round(
								(100 * dadoMes.comprometido) / dadoMes.espaco
							) + "%",
						colspan: 1,
					});
				}

				headersTotal.push({
					text: (dadoMes.oportunidade_v * 1).toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					}),
					colspan: 1,
				});
			}

			return headersTotal;
		},
	},
	methods: {
		getEstacao(mes) {
			return 4 <= mes && mes <= 8 ? "inverno" : "verao";
		},
		mudaLayout() {
			this.layoutCompacto = !this.layoutCompacto;

			setTimeout(() => {
				this.$refs.grafico1.chart.reflow();
				this.$refs.grafico2.chart.reflow();
			}, 100);
		},
		getClass(valor, tipo) {
			if (!valor) {
				return "";
			}
			if (valor.slice(0, -1) * 1 > 80) {
				return "circulo vermelho";
			}
			if (valor.slice(0, -1) * 1 > 50) {
				return "circulo amarelo";
			}
			return "circulo verde";
		},
		expandMes() {
			this.expand = !this.expand;
			this.expandIcon = this.expand ? "mdi-minus" : "mdi-plus";
		},
		atualizaPeriodo() {
			try {
				this.mesInicial = this.$refs.filtro.filtro.mesInicial;
				this.mesFinal = this.$refs.filtro.filtro.mesFinal;
			} catch (error) {
				//filtro ainda não foi carregado
			}
		},
		getPeriodo() {
			var mesAtual = new Date(this.mesInicial + "-15");
			var mesFinal = new Date(this.mesFinal + "-15");

			var periodo = [];

			while (mesAtual <= mesFinal) {
				let valor =
					mesAtual.getFullYear() + "_" + (mesAtual.getMonth() + 1);

				let nome = this.$moment([
					mesAtual.getFullYear(),
					mesAtual.getMonth(),
					15,
				]).format("MMMM  YYYY");
				nome = nome.charAt(0).toUpperCase() + nome.slice(1);

				let mes =
					mesAtual.getFullYear() +
					"-" +
					("0" + (mesAtual.getMonth() + 1)).slice(-2) +
					"-01";

				let ano = mesAtual.getFullYear();

				if (this.dadosMes.find((e) => e.mes == mes)) {
					periodo.push({
						nome: nome,
						valor: valor,
						mes: mes,
						ano: ano,
					});
				}

				mesAtual.setMonth(mesAtual.getMonth() + 1);
			}

			return periodo;
		},
		getHeadersLength() {
			const camposNaoExibidas = ['uf'];
			const qtdcamposNaoExibidas = this.campos.filter(c => !camposNaoExibidas.includes(c)).length;
			return qtdcamposNaoExibidas;
		},
		labels() {
			var labels = {};

			for (const h of this.headers) {
				labels[h.value] = h.text + " " + h.grupo;
			}

			return labels;
		},
		fields() {
			var fields = [];

			for (const h of this.headers) {
				fields.push(h.value);
			}

			return fields;
		},
		// async listaCampos() {
		// 	var response = await this.$http().post(
		// 		"/gerencial/filtrosOportunidade",
		// 		{}
		// 	);

		// 	return response.data;
		// },
		checkAgrupadas() {
			let _campos = this.campos || [];
			let _espaco =
				_campos.filter((e) => ["espaco", "espaco_macro"].includes(e))
					.length > 0;

			let _java = _campos.includes("id_java");

			this.agrupadosPage = _espaco && _java;
		},
		async getOportunidadeLista(limpaOrdem = false, data) {
			this.loadLista = true;
			this.dados = [];
			this.total = 0;

			if (limpaOrdem) {
				this.pagination.sortBy = [];
				this.pagination.sortDesc = [];
				this.pagination.page = 1;
			}

			data.pagination = this.$helpers.cloneObj(this.pagination);

			if (!this.agrupadosPage) {
				delete data.pagination.itemsPerPage;
			}

			var response = await this.$http().post("/gerencial/oportunidade", {
				data: data,
			});

			//console.log("response=>", response);

			if (response.data.lista.severity) {
				this.$dialog.notify.error(
					"Falha ao buscar os dados das tabelas, por favor, tente novamente.",
					{
						position: "top-right",
						timeout: 5000,
						width: "",
						style: `width: ${window.innerWidth * 0.8}px`,
					}
				);

				this.loadLista = false;
				return;
			}

			this.fnFiltrando = false;

			this.dados = response.data.lista;
			this.total = response.data.lista.length
				? response.data.lista[0].total
				: 0;

			this.loadLista = false;

			/* if (this.total == 0 && this.pagination.page > 1) {
				this.pagination.page = 1;
				await this.filtrar(false, true);
				return;
			} */
		},
		async getOportunidadeMes(data) {
			this.loadMes = true;
			this.graficoMes.xAxis.categories = [];
			this.graficoMes.series[0].data = [];
			this.graficoMes.series[1].data = [];

			this.graficoAno.xAxis.categories = [];
			this.graficoAno.series[0].data = [];
			this.graficoAno.series[1].data = [];

			var response = await this.$http().post(
				"/gerencial/oportunidadeMes",
				{
					data: data,
				}
			);

			this.fnFiltrando = false;

			if (response.data.mes.severity) {
				this.$dialog.notify.error(
					"Falha ao buscar os dados dos gráficos, por favor, tente novamente.",
					{
						position: "top-right",
						timeout: 5000,
						width: "",
						style: `width: ${window.innerWidth * 0.8}px`,
					}
				);

				this.loadMes = false;
				return;
			}

			this.dadosMes = response.data.mes;
			this.dtLastUpdate = response.data.dt_last_update;

			let graficoMes = {
				comprometido: [],
				investimento: [],
				oportunidade: [],
				oportunidade_v: [],
				nome: [],
			};

			let dadosAno = {
				espaco: [],
				comprometido: [],
				investimento: [],
				oportunidade: [],
				oportunidade_v: [],
			};

			let anos = [];

			for (const p of this.getPeriodo()) {
				if (!anos.includes(p.ano)) {
					anos.push(p.ano);

					dadosAno.espaco[p.ano] = 0;
					dadosAno.comprometido[p.ano] = 0;
					dadosAno.investimento[p.ano] = 0;
					dadosAno.oportunidade[p.ano] = 0;
					dadosAno.oportunidade_v[p.ano] = 0;
				}

				let dadoMes = this.dadosMes.find((e) => e.mes == p.mes);

				dadosAno.espaco[p.ano] += dadoMes.espaco * 1;
				dadosAno.comprometido[p.ano] += dadoMes.comprometido * 1;
				dadosAno.investimento[p.ano] += dadoMes.investimento * 1;
				dadosAno.oportunidade[p.ano] += dadoMes.oportunidade * 1;
				dadosAno.oportunidade_v[p.ano] += dadoMes.oportunidade_v * 1;

				graficoMes.comprometido.push(
					Math.round(
						dadoMes.espaco
							? (100 * dadoMes.comprometido) / dadoMes.espaco
							: 0
					)
				);

				graficoMes.oportunidade.push(
					Math.round(
						dadoMes.espaco
							? (100 * dadoMes.oportunidade) / dadoMes.espaco
							: 0
					)
				);
				graficoMes.investimento.push(
					(dadoMes.investimento * 1).toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					})
				);
				graficoMes.oportunidade_v.push(
					(dadoMes.oportunidade_v * 1).toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					})
				);
				graficoMes.nome.push(p.nome);

				this.indicadores.comprometido_p =
					Math.round((100 * dadoMes.comprometido) / dadoMes.espaco) +
					"%";
				this.indicadores.espaco_v = (
					dadoMes.espaco_v * 1
				).toLocaleString("pt-br", {
					style: "currency",
					currency: "BRL",
				});
				this.indicadores.investimento = (
					dadoMes.investimento * 1
				).toLocaleString("pt-br", {
					style: "currency",
					currency: "BRL",
				});
				this.indicadores.oportunidade_v = (
					dadoMes.oportunidade_v * 1
				).toLocaleString("pt-br", {
					style: "currency",
					currency: "BRL",
				});
			}

			this.graficoMes.xAxis.categories = graficoMes.nome;

			this.graficoMes.series[0].data = graficoMes.comprometido;
			this.graficoMes.series[1].data = graficoMes.oportunidade;

			graficoMesGlobal = {
				comprometido: graficoMes.investimento,
				oportunidade: graficoMes.oportunidade_v,
			};

			this.graficoAno.xAxis.categories = [];
			this.graficoAno.series[0].data = [];
			this.graficoAno.series[1].data = [];

			graficoAnoGlobal = {
				comprometido: [],
				oportunidade: [],
			};

			for (const a of anos) {
				this.graficoAno.xAxis.categories.push(a);

				this.graficoAno.series[0].data.push(
					Math.round(
						dadosAno.espaco[a]
							? (100 * dadosAno.comprometido[a]) /
									dadosAno.espaco[a]
							: 0
					)
				);
				this.graficoAno.series[1].data.push(
					Math.round(
						dadosAno.espaco[a]
							? (100 * dadosAno.oportunidade[a]) /
									dadosAno.espaco[a]
							: 0
					)
				);

				graficoAnoGlobal.comprometido.push(
					(dadosAno.investimento[a] * 1).toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					})
				);
				graficoAnoGlobal.oportunidade.push(
					(dadosAno.oportunidade_v[a] * 1).toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					})
				);
			}

			this.loadMes = false;
		},
		//async filtrar(limpaOrdem = false) {
		async filtrar(limpaOrdem = false, isPagination = false) {
			if (!this.$refs.filtro.validate()) return;

			//this.carregando = true;
			this.fnFiltrando = true;

			if (this.$refs.filtro.filtro.mesInicial == undefined) {
				this.$refs.filtro.iniciaFiltro();
			}

			if (!this.$refs.filtro.filtro.campo.length) {
				this.$refs.filtro.filtro.campo = ["cluster"];
			}

			this.campos = this.$refs.filtro.filtro.campo;

			this.checkAgrupadas();

			this.atualizaPeriodo();

			var filtro = { ...this.$refs.filtro.filtro };

			var data = {};

			data.mesInicial = filtro.mesInicial;
			data.mesFinal = filtro.mesFinal;

			data.id_filial = filtro.id_filial;
			data.bandeira = filtro.bandeira;
			data.id_espaco = filtro.id_espaco;
			data.espaco_macro = filtro.espaco_macro;
			data.id_cluster = filtro.id_cluster;
			data.regiao = filtro.regiao;
			data.perfil = filtro.perfil;
			data.tamanho = filtro.tamanho;
			data.check_out = filtro.check_out;
			data.campo = filtro.campo;
			data.realtime = filtro.realtime;
			data.uf = filtro.uf;

			if (data.campo.includes("vl_unitario") && data.realtime) {
				this.$dialog.notify.warning(
					'A visão [Valor Unit] só é possível nos dados pré-processados, favor remover o flag "Real time".',
					{
						position: "top-right",
						timeout: 15000,
						width: window.innerWidth,
						style: `width: ${window.innerWidth * 0.8}px`,
					}
				);

				return;
			}

			this.getOportunidadeLista(limpaOrdem, data);

			if (!isPagination) this.getOportunidadeMes(data);
		},

		async exportar(tipoExportacao) {
			if (!this.$refs.filtro.validate()) return;

			this.loadLista = true;

			if (!this.$refs.filtro.filtro.campo.length) {
				this.$refs.filtro.filtro.campo = ["cluster"];
			}

			var filtro = this.$refs.filtro.filtro;

			var data = {};

			data.mesInicial = filtro.mesInicial;
			data.mesFinal = filtro.mesFinal;

			data.id_filial = filtro.id_filial;
			data.bandeira = filtro.bandeira;
			data.id_espaco = filtro.id_espaco;
			data.espaco_macro = filtro.espaco_macro;
			data.id_cluster = filtro.id_cluster;
			data.regiao = filtro.regiao;
			data.perfil = filtro.perfil;
			data.tamanho = filtro.tamanho;
			data.check_out = filtro.check_out;
			data.campo = filtro.campo;
			data.realtime = filtro.realtime;
			data.tipoExportacao = tipoExportacao;

			var response = await this.$http().post(
				"/gerencial/exportarOportunidade",
				{ data: data }
			);

			window.location = this.$http("baseURL") + response.data.url;

			this.loadLista = false;
		},
	},
};
</script>

<style>
.highcharts-background {
	width: 100%;
}

.stackLabels {
	font-family: "TT Norms", "Work Sans", sans-serif;
	font-weight: 500;
	font-size: 0.75rem;
	color: #808080;
	margin-bottom: 5px;
}
.stackLabels .oportunidade {
	text-align: center;

	color: var(--v-primary-base);
}
.stackLabels .comprometido {
	text-align: center;
	color: var(--v-yellow-base);
}
.grafico {
	position: relative;
}
.grafico-frame {
	top: 45px;
	width: 100%;
	height: calc(100% - 45px);
	border: 2px solid #f2f2f2;
	border-radius: 15px 15px 0 0;
	position: absolute;
	overflow: hidden;
}
.grafico-frame::before {
	content: "";
	width: 100%;
	height: 58px;
	background: #f2f2f2;
	bottom: 0;
	position: absolute;
	border-top: 1px solid #ddd;
}

.tabela {
	overflow: hidden;
	color: #808080 !important;
}
.tabela .v-data-table__wrapper {
	border-radius: 10px 10px 0 0;
	border: 1px solid #eaeaea !important;
}

tr.total td {
	text-align: center;
	background-color: #f2f2f2;
}

tr.total td:nth-child(odd) {
	background-color: #f8f8f8;
}

.tabela td {
	font-size: 0.8rem !important;
}
.header-dark {
	background: var(--v-primary-darken1);
}
.circulo {
	border-radius: 50%;
	width: 10px;
	height: 10px;
	display: inline-block;
	margin-right: 5px;
}
.circulo.verde {
	background: var(--v-primary-base);
}
.circulo.amarelo {
	background: #ff9f1c;
}
.circulo.vermelho {
	background: var(--v-red-base);
}
.expand-buttons .v-input--radio-group__input {
	font-size: 0.8rem;
	justify-content: space-between;
	display: flex;
	align-items: center;
	padding: 0 20px;
}
.expand-buttons .v-label {
	font-size: 0.8rem;
	color: #fff;
}
.expand-buttons .v-input--selection-controls__input {
	transform: scale(70%);
	margin: 0;
}
.expand-buttons .v-radio {
	margin-right: 5px !important;
}
.center-cell {
	display: flex;
	align-items: center;
	justify-content: center;
}
.td-taxa-ocupacao {
	display: inline-flex;

	justify-content: center;
	align-items: center;
	height: 100%;
}
.legenda-oportunidades {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f2f2f2;
	border-radius: 6px;
	padding: 8px 0;
	color: #808080;
	font-size: 0.8rem;
	font-weight: normal;
}
.legenda-oportunidades .comprometido {
	background: var(--v-yellow-base);
	width: 25px;
	height: 20px;
	margin: 0 12px;
	border-radius: 4px;
}
.legenda-oportunidades .oportunidade {
	background: var(--v-primary-base);
	width: 25px;
	height: 20px;
	margin: 0 12px;
	border-radius: 4px;
}
</style>
